import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FeatureFlagService } from "./services/featureflag.service";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatBadgeModule } from "@angular/material/badge";
import { ConfirmComponent } from "./components/alert/confirm/confirm.component";
import { ConflowConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogAssuredComponent } from "./components/confirm-dialog-assured/confirm-dialog-assured.component";
import { DateformatPipe } from "./pipes/dateformat.pipe";
import { MatTreeModule} from "@angular/material/tree";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { UnauthorizedRequestInterceptor } from "./http-interceptor.service";
import { DivaLibModule } from "diva-lib";
import { AlertService as DivaAlertService } from "diva-lib";
import { LoadingOverlayComponent } from "./components/loading-overlay/loading-overlay.component";
import { MatListModule } from "@angular/material/list";
@NgModule({
  declarations: [
    ConfirmComponent,
    ConflowConfirmDialogComponent,
    DateformatPipe,
    ConfirmDialogAssuredComponent,
    LoadingOverlayComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatToolbarModule,
    MatTooltipModule,
    MatRadioModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatOptionModule,
    TranslateModule,
    MatNativeDateModule,
    MatSortModule,
    MatTooltipModule,
    MatTreeModule,
    MatPaginatorModule,
    MatDividerModule,
    RouterModule,
    DivaLibModule
  ],
  exports: [
    CommonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatCardModule,
    ConfirmComponent,
    MatToolbarModule,
    MatTooltipModule,
    MatRadioModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatOptionModule,
    TranslateModule,
    MatNativeDateModule,
    MatSortModule,
    DateformatPipe,
    MatTooltipModule,
    MatPaginatorModule,
    MatDividerModule,
    RouterModule,
    DivaLibModule
  ],
  providers: [
    FeatureFlagService,
    DivaAlertService,
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedRequestInterceptor, multi: true },
  ]
})

export class SharedModule { }
