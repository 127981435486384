import { sequence, trigger, keyframes, animate, style, group, query, transition, animateChild, state, animation, useAnimation, stagger } from "@angular/animations";

const customAnimation = animation([
  style({
    opacity: "{{opacity}}",
    transform: "scale({{scale}}) translate3d({{x}}, {{y}}, {{z}})"
  }),
  animate("{{duration}} {{delay}} cubic-bezier(0.0, 0.0, 0.2, 1)", style("*"))
], {
  params: {
    duration: "200ms",
    delay: "0ms",
    opacity: "0",
    scale: "1",
    /* eslint-disable id-length */
    x: "0",
    y: "0",
    z: "0"
    /* eslint-enable id-length */
  }
});

const shortTransitionTimings = 100;
const longTransitionTimings = 1000;

export const conflowAnimations = [

  trigger("animate", [transition("void => *", [useAnimation(customAnimation)])]),

  trigger("fadeInOut", [

    transition(":enter", [
      style({opacity: 0}),
      animate(longTransitionTimings)
    ]),

    transition(":leave",
      animate(longTransitionTimings, style({opacity: 0})))
  ]),

  trigger("slideInLeft", [
    state("void", style({
      transform: "translateX(-100%)",
    })),
    state("*", style({
      transform: "translateX(0)",
    })),
    transition("void => *", animate("300ms")),
    transition("* => void", animate("300ms"))
  ]),

  trigger("slideInRight", [
    state("void", style({
      transform: "translateX(100%)",
    })),
    state("*", style({
      transform: "translateX(0)",
    })),
    transition("void => *", animate("300ms")),
    transition("* => void", animate("300ms"))
  ]),

  trigger("bouceIn", [
    transition(":enter", [
      animate(shortTransitionTimings, keyframes([
        style({animationTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)", opacity: 0, transform: "scale3d(.3, .3, .3)", offset: 0}),
        style({animationTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)", transform: "scale3d(1.1, 1.1, 1.1)", offset: .2}),
        style({animationTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)", transform: "scale3d(.9, .9, .9)", offset: .4}),
        style({animationTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)", opacity: 1, transform: "scale3d(1.03, 1.03, 1.03)", offset: .6}),
        style({animationTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)", transform: "scale3d(.97, .97, .97)", offset: .8}),
        style({animationTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)", opacity: 1, transform: "scale3d(1, 1, 1)", offset: 1}),
      ]))
    ]),
  ]),

  trigger("fadeInRightLeft", [
    transition(":enter", [
      animate(shortTransitionTimings, keyframes([
        style({opacity: 0, transform: "translate3d(100%, 0, 0)", offset: 0}),
        style({opacity: 1, transform: "none", offset: 1}),
      ]))
    ]),
    transition(":leave", [
      animate(shortTransitionTimings, keyframes([
        style({opacity: 1, animationTimingFunction: "ease-in", transform: "none", offset: 0}),
        style({opacity: 0, animationTimingFunction: "ease-in", transform: "translate3d(100%, 0, 0)", offset: 1}),
      ])),

    ]),
  ]),

  trigger("animateStagger", [
    state("50", style("*")),
    state("100", style("*")),
    state("200", style("*")),

    transition("void => 50",
      query("@*",
        [
          stagger("50ms", [
            animateChild()
          ])
        ], {optional: true})),
    transition("void => 100",
      query("@*",
        [
          stagger("100ms", [
            animateChild()
          ])
        ], {optional: true})),
    transition("void => 200",
      query("@*",
        [
          stagger("200ms", [
            animateChild()
          ])
        ], {optional: true}))
  ]),

  trigger("fadeInOut", [
    state("0", style({
      display: "none",
      opacity: 0
    })),
    state("1", style({
      display: "block",
      opacity: 1
    })),
    transition("1 => 0", animate("300ms ease-out")),
    transition("0 => 1", animate("300ms ease-in"))
  ]),

  trigger("slideInOut", [
    state("0", style({
      height: "0px",
      display: "none"
    })),
    state("1", style({
      height: "*",
      display: "block"
    })),
    transition("1 => 0", animate("300ms ease-out")),
    transition("0 => 1", animate("300ms ease-in"))
  ]),

  trigger("slideIn", [
    transition("void => left", [
      style({
        transform: "translateX(100%)"
      }),
      animate("300ms ease-in",
        style({
          transform: "translateX(0)"
        })
      )
    ]
    ),
    transition("left => void", [
      style({
        transform: "translateX(0)"
      }),
      animate("300ms ease-in",
        style({
          transform: "translateX(-100%)"
        })
      )
    ]
    ),
    transition("void => right", [
      style({
        transform: "translateX(-100%)"
      }),
      animate("300ms ease-in",
        style({
          transform: "translateX(0)"
        })
      )
    ]
    ),
    transition("right => void", [
      style({
        transform: "translateX(0)"
      }),
      animate("300ms ease-in",
        style({
          transform: "translateX(100%)"
        })
      )
    ]
    ),
  ]),

  trigger("slideInTop", [
    state("void", style({
      transform: "translateY(-100%)",
      display: "none"
    })),
    state("*", style({
      transform: "translateY(0)",
      display: "flex"
    })),
    transition("void => *", animate("300ms")),
    transition("* => void", animate("300ms"))
  ]),

  trigger("slideInBottom", [
    state("void",
      style({
        transform: "translateY(100%)",
        display: "none"
      })),
    state("*", style({
      transform: "translateY(0)",
      display: "flex"
    })),
    transition("void => *", animate("300ms")),
    transition("* => void", animate("300ms"))
  ]),

  trigger("expandCollapse", [
    state("void", style({
      height: "0px"
    })),
    state("*", style({
      height: "*"
    })),
    transition("void => *", animate("300ms ease-out")),
    transition("* => void", animate("300ms ease-in"))
  ]),

  // -----------------------------------------------------------------------------------------------------
  // @ Router animations
  // -----------------------------------------------------------------------------------------------------

  trigger("routerTransitionLeft", [

    transition("* => *", [
      query("content > :enter, content > :leave", [
        style({
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        })
      ], {optional: true}),
      query("content > :enter", [
        style({
          transform: "translateX(100%)",
          opacity: 0
        })
      ], {optional: true}),
      sequence([
        group([
          query("content > :leave", [
            style({
              transform: "translateX(0)",
              opacity: 1
            }),
            animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
              style({
                transform: "translateX(-100%)",
                opacity: 0
              }))
          ], {optional: true}),
          query("content > :enter", [
            style({transform: "translateX(100%)"}),
            animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
              style({
                transform: "translateX(0%)",
                opacity: 1
              }))
          ], {optional: true})
        ]),
        query("content > :leave", animateChild(), {optional: true}),
        query("content > :enter", animateChild(), {optional: true})
      ])
    ])
  ]),

  trigger("routerTransitionRight", [

    transition("* => *", [
      query("content > :enter, content > :leave", [
        style({
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        })
      ], {optional: true}),
      query("content > :enter", [
        style({
          transform: "translateX(-100%)",
          opacity: 0
        })
      ], {optional: true}),
      sequence([
        group([
          query("content > :leave", [
            style({
              transform: "translateX(0)",
              opacity: 1
            }),
            animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
              style({
                transform: "translateX(100%)",
                opacity: 0
              }))
          ], {optional: true}),
          query("content > :enter", [
            style({transform: "translateX(-100%)"}),
            animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
              style({
                transform: "translateX(0%)",
                opacity: 1
              }))
          ], {optional: true})
        ]),
        query("content > :leave", animateChild(), {optional: true}),
        query("content > :enter", animateChild(), {optional: true})
      ])
    ])
  ]),

  trigger("routerTransitionUp", [

    transition("* => *", [
      query("content > :enter, content > :leave", [
        style({
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        })
      ], {optional: true}),
      query("content > :enter", [
        style({
          transform: "translateY(100%)",
          opacity: 0
        })
      ], {optional: true}),
      group([
        query("content > :leave", [
          style({
            transform: "translateY(0)",
            opacity: 1
          }),
          animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
            style({
              transform: "translateY(-100%)",
              opacity: 0
            }))
        ], {optional: true}),
        query("content > :enter", [
          style({transform: "translateY(100%)"}),
          animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
            style({
              transform: "translateY(0%)",
              opacity: 1
            }))
        ], {optional: true})
      ]),
      query("content > :leave", animateChild(), {optional: true}),
      query("content > :enter", animateChild(), {optional: true})
    ])
  ]),

  trigger("routerTransitionDown", [

    transition("* => *", [
      query("content > :enter, content > :leave", [
        style({
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        })
      ], {optional: true}),
      query("content > :enter", [
        style({
          transform: "translateY(-100%)",
          opacity: 0
        })
      ], {optional: true}),
      sequence([
        group([
          query("content > :leave", [
            style({
              transform: "translateY(0)",
              opacity: 1
            }),
            animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
              style({
                transform: "translateY(100%)",
                opacity: 0
              }))
          ], {optional: true}),
          query("content > :enter", [
            style({transform: "translateY(-100%)"}),
            animate("600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
              style({
                transform: "translateY(0%)",
                opacity: 1
              }))
          ], {optional: true})
        ]),
        query("content > :leave", animateChild(), {optional: true}),
        query("content > :enter", animateChild(), {optional: true})
      ])
    ])
  ]),

  trigger("routerTransitionFade", [

    transition("* => *", group([

      query("content > :enter, content > :leave ", [
        style({
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        })
      ], {optional: true}),

      query("content > :enter", [
        style({
          opacity: 0
        })
      ], {optional: true}),
      query("content > :leave", [
        style({
          opacity: 1
        }),
        animate("300ms cubic-bezier(0.0, 0.0, 0.2, 1)",
          style({
            opacity: 0
          }))
      ], {optional: true}),
      query("content > :enter", [
        style({
          opacity: 0
        }),
        animate("300ms cubic-bezier(0.0, 0.0, 0.2, 1)",
          style({
            opacity: 1
          }))
      ], {optional: true}),
      query("content > :enter", animateChild(), {optional: true}),
      query("content > :leave", animateChild(), {optional: true})
    ]))
  ])
];
