import { Injectable } from "@angular/core";
import { Subject, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ConflowSettings } from "src/app/shared/settings/conflow-settings";
import { Space } from "src/app/shared/models/space";
import { ConflowUser } from "src/app/shared/models/conflow-user";
import { Channel } from "src/app/shared/models/channel";
import { Tag } from "src/app/shared/models/tag";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  public userProfile: object;
  public toggleSwitchTheme: Subject<string>;
  public profile: object;
  /** All users in current space */
  public usersForSpace: ConflowUser[];
  /** All spaces for the operating user */
  public spaceForUser: Space[];
  public user: ConflowUser;
  public onUserInfoLoad: Subject<any>;
  public showProgressBar: boolean;
  public showFileGhostList: boolean;

  private selectedSpaceId: string;
  public selectedSpace: Space;
  public onSpaceChanged: Subject<any>;
  public toggleSideBar: Subject<any>;
  public isSpaceOwner: any;
  public channelUser: ConflowUser = {
    id: "00000000-0000-0000-0000-000000000000", // Guid.Empty
    email: "allusers@conflow.com",
    userName: "channel",
    avatar: "assets/icons/logos/divacloud_logo_45x60.svg",
    status: "",
    mood: "",
    nickName: "",
    channelList: [],
    spaces: [],
  };

  // badgeUnreadCount: string;
  // showUnreadMsgOnExpand: boolean;

  // store temp data to display
  public storeChannelList: Channel[] = []; // channel list
  public storeChannelData: any = []; // channel conversation
  public storeChannelMemberData: any = []; // channel member conversation
  /** Custom tags for Space store */
  public storeCustomTags: Tag[] = [];
  public onSpaceUsersLoaded: Subject<any>;
  public isSpaceUserLoaded: boolean=false;

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.toggleSwitchTheme = new Subject();
    this.onUserInfoLoad = new Subject();
    this.onSpaceChanged = new Subject();
    this.toggleSideBar = new Subject();
    this.onSpaceUsersLoaded=new Subject();
    this.isSpaceOwner = false;
  }
  public get currentSpaceId() {
    return sessionStorage.getItem("userSpaceID");
  }

  public get currentUserId() {
    return localStorage.getItem("userID");
  }

  /**
   * Get user profile
   *
   */
  getUserProfile() {
    return this.profile;
  }

  /**
   * Set Profile
   *
   * @param profile
   */
  setProfile(profile) {
    this.profile = profile;
  }

  /**
   * Set User for Space
   *
   * @param spaceForUser
   */
  setUsersForSpace(usersForSpace) {
    for(const user of usersForSpace) {
      if(!user.userName) {
        user.userName = user.email;
      }
    }
    this.usersForSpace = usersForSpace;
    this.isSpaceUserLoaded=true;
    this.onSpaceUsersLoaded.next(false);
  }

  /**
   * get Users  for Space Id
   *
   * @returns {Observable<any>}
   */
  getUsersForSpace(spaceId: string): Observable<any> {
    return this.getUsersForSpaceFromBackend(spaceId)
      .pipe(
        map((res) => {
          this.setUsersForSpace(res);
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * get Users for SpaceId
   * @param spaceId
   */
  getUsersForSpaceFromBackend(spaceId: string): Observable<any> {
    return this.httpClient
      .get(
        `${ConflowSettings.BASE_API_URL}Space/${spaceId}/users`,
        { headers: ConflowSettings.httpHeaders }
      );
  }

  /**
   * Set Space  for User
   *
   * @param spaceForUser
   */
  setSpaceForUser(spaceForUser) {
    this.spaceForUser = spaceForUser;
    const localSpace = this.spaceForUser.find(
      (space) => space.id === sessionStorage.getItem("userSpaceID")
    );

    localSpace
      ? this.setActiveSpaceIdForUser(localSpace)
      // Set the first space on the space list
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      : this.setActiveSpaceIdForUser(spaceForUser[0]);
  }
  setActiveSpaceIdForUser(space) {
    this.selectedSpace = space;
    this.setSpaceIdForUser(space.id);
  }

  setSpaceIdForUser(spaceId) {
    this.selectedSpaceId = spaceId;
    sessionStorage.setItem("userSpaceID", this.selectedSpaceId);
    localStorage.setItem("userSpaceID", this.selectedSpaceId);
    this.onSpaceChanged.next(spaceId);
  }
  /**
   * get Space for User By userId
   *
   * @returns {Observable<any>}
   */
  getSpaceForUser(userId: string): Observable<any> {
    return this.httpClient
      .get(
        `${ConflowSettings.BASE_API_URL}Users/spaces`,
        { headers: ConflowSettings.httpHeaders }
      );
  }

  /**
   * Set User
   *
   * @param spaceForUser
   */
  setUser(user) {
    this.user = user;
    localStorage.setItem("userID", user.id); // for launch darkly key generation
  }

  /**
   * Get user
   *
   * @returns {Observable<any>}
   */
  getUser(): Observable<any> {
    return this.getUserFromBackend()
      .pipe(
        map((res) => {
          this.setUser(res);
          this.onUserInfoLoad.next(res);
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getUserFromBackend(): Observable<any> {
    return this.httpClient
      .get(
        `${ConflowSettings.BASE_API_URL}Users`,
        { headers: ConflowSettings.httpHeaders }
      );
  }

  isUserInfoLoaded(): Observable<boolean> {
    if(!this.user) {
      return of(false);
    }
    return of(true);
  }

  setDefaultChannel(channelId: string) {
    localStorage.setItem(`channelID${this.user.id}`, channelId);
  }

  getDefaultChannel(): string {
    return localStorage.getItem(`channelID${this.user.id}`);
  }

  /**
   * get Users  for Space Id
   *
   * @returns {Observable<any>}
   */
  checkUserIsOwnerOfSpace(spaceId: string): Observable<any> {
    return this.checkUserIsOwnerOfSpaceFromBackend(spaceId)
      .pipe(
        map((res) => {
          this.isSpaceOwner = res;
          return res;
        })
      );
  }

  /**
   * Checks a User is space owner or not
   *
   * @returns {Observable<any>}
   */
  checkUserIsOwnerOfSpaceFromBackend(spaceId:string): Observable<any> {
    return this.httpClient
      .get(
        `${ConflowSettings.BASE_API_URL}Space/${spaceId}/checkowner`,
        { headers: ConflowSettings.httpHeaders }
      );
  }

  /**
   * Change space and reload
   */
  changeSpace(spaceId): void {
    this.setSpaceIdForUser(spaceId);
    if (window.location.pathname === "/permission/no-access") {
      this.router.navigateByUrl("dashboard");
    } else {
      window.location.href = this.router.url;
    }
  }

  public hasSpaceChange(url: string): void {
    const spaceIdInUrlIndex = 1;
    let spaceid = "";

    const mat = url.match(/^\/dashboard\/([\w-]+)\/actionflow\/([\w-]+)$/);

    if (mat && mat[spaceIdInUrlIndex]) {
      spaceid = mat[spaceIdInUrlIndex];
    }

    if (spaceid !== "") {
      console.log("found space id in route, change space", spaceid);
      this.setSpaceIdForUser(spaceid);
    }
  }
}
