export const locale = {
  lang: "en",
  localeData: {
    "actionflow": {
      "startactionflow": "Start Task",
      "editactionflow": "Edit Task",
      "createactionflow": "Create Task",
      "report": "Task Report",
      "information": "Information",
      "comment": "Comment",
      "logs": "Logs",
      "loading": "loading actions..",
      "required": "required",
      "actions": {
        "doaction": "Start",
        "rerun": "Restart",
        "cancel": "N/A",
        "approval": "Approve",
      },
      "commands": {
        "create": "Create Task..",
        "export": "Export..",
        "reload": "Reload",
        "update": "Update Task..",
        "clone": "Clone..",
        "delete": "Delete..",
        "deletemessage": "Are you sure you want to delete this flow?",
        "deletetitle": "Delete Task",
        "save": "Save",
        "loadmore": "Load more",
        "back": "Back",
        "loaduser": "Load User Name.."
      },
      "properties": {
        "id": "ID",
        "name": "Name",
        "nameexample": "Eg. Sales (international)",
        "status": "Status",
        "action": "Action",
        "actorexample": "Actor",
        "actorlabel": "DisplayLabel",
        "actorlabelexample": "Eg. John",
        "step": "Step",
        "completed": "Completed",
        "lastacteduser": "Last Acted User",
        "lastacteddate": "Last Acted Date",
        "customfield": "CustomField",
        "basicinfo": "Basic Information",
        "stepinfo": "Steps"
      },
      "steps": {
        "cancel": "N/A",
        "charger": "Charger",
        "cross": "Cross Check",
        "cross1": "Cross 1 Check",
        "cross2": "Cross 2 Check",
        "chief": "Chief",
        "manager": "Manager",
      },
      "messages": {
        "hasunknownuser": "Unknown user exists. Please confirm the setting.",
        "nodiststeponrerun": "Please select the step to return when rerun.",
        "nocomment": "Please input a comment.",
        "hasempty": "Please input all fields.",
      },
      "templates": {
        "label": "message templates",
        "1": {
          "name": "For Approval Request",
          "text": "-Content\n\n\n-Save Folder\n\n\n-Stamp Folder\n\n\n-Comment\n\n\n",
        },
        "2": {
          "name": "For Remand",
          "text": "-Comment\n\n\n-Documents Folder\n\n\n",
        }
      },
      "errors": {
        "nomessage": "please enter a message.",
        "notselectbackto": "please select a target step of back to.",
        "taskdeleted": "This Task is deleted.",
        "removeassociation": "This Task is removed from channel."
      },
      "notfound": {
        "contenttitle": "TASK NOT AVAILABLE",
        "message": "This task has been deleted.",
        "action": "Go Back To Channel"
      },
      "filter": {
        "clear": "Clear Filter",
        "all": "Select All/Unselect All"
      },
      "detail": {
        "banner": {
          "informationmessage": "There is new update in this task.",
          "instructionmessage": "Please refresh the page.",
          "actionbuttontext": "Refresh"
        }
      }
    }
  }
};
