import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend, HttpHeaders } from "@angular/common/http";
import { IAppConfig } from "src/app/shared/models/app-config";
import { Observable, Subject, catchError, map, throwError } from "rxjs";
import { ConflowSettings } from "./shared/settings/conflow-settings";

@Injectable({
  providedIn: "root",
})
export class AppConfig {
  static settings: IAppConfig;
  static config = new Subject();
  constructor(private httpBackend: HttpBackend) {
  }

  load(): Observable<void> {
    let headers = new HttpHeaders();

    headers = headers.append(ConflowSettings.noAuthValidateHeaderParam, "true"); // Add this header to skip auth0 authorization by interceptor

    const http: HttpClient = new HttpClient(this.httpBackend);
    const jsonFile = "assets/config/config.json";

    return http.get(jsonFile, { headers }).pipe(
      map((response: IAppConfig) => {
        AppConfig.settings = response;
        AppConfig.config.next(AppConfig.settings);
      }),
      catchError(error => {
        return throwError(() => `Could not load file '${jsonFile}': ${JSON.stringify(error)}`);
      })
    );
  }
}