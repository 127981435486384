import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { locale as en } from "src/plugins/actionflow/assets/i18n/en";
import { locale as ja } from "src/plugins/actionflow/assets/i18n/ja";

@Injectable({
  providedIn: "root"
})

export class ActionFlowLoaderService {
  /**
   * Constructor
   *
   * @param {TranslateService} translateService
   */
  constructor(
        private translateService: TranslateService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Load
   */
  load(): void {
    // add resource
    this.translateService.setTranslation(en.lang, en.localeData, true);
    this.translateService.setTranslation(ja.lang, ja.localeData, true);

    // add any logic here if you have

    // fin
    console.log("* actionflow plugin loaded.");
  }
}
