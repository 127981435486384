import { languages } from "src/app/shared/const/lang";
import { FeatureFlags } from "src/app/shared/const/featureflags";
import { CommonUtils } from "src/app/shared/utils/common-utils";
import { HttpHeaders } from "@angular/common/http";
import { AppConfig } from "src/app/app.config";

export class ConflowSettings {
  public static get BASE_API_URL(): string {
    return AppConfig.settings.backendurl;
  }
  public static get TASK_BFF_URL(): string {
    return AppConfig.settings.taskBffUrl;
  }

  public static rewriteUrl(url: string): string {
    return url;
  }

  public static get defaultLanguage(): string {
    const englishIndex = 1;

    return languages[englishIndex].id;
  }

  public static get getLanguagesIdInArray(): Array<string> {
    return languages.map(language => language.id);
  }

  public static get httpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    // var space = localStorage.getItem('userSpaceID');
    // if (space) headers = headers.append("Space", space);

    return headers = CommonUtils.appendAuthorizationHeader(headers);
  }

  public static get noAuthValidateHeaderParam(): string {
    return "NoAuthValidate";
  }

  public static get FeatureFlags(): object {
    return FeatureFlags;
  }

  public static get LaunchDarklyKey(): string {
    return AppConfig.settings.launchDarkleyKey;
  }

  public static get LaunchDarklyClientID(): string {
    return AppConfig.settings.launchDarkleyclientID;
  }

  // --------------individual service urls --------------------------//
  public static get domain(): any {
    return `${AppConfig.settings.domain}`;
  }

  public static get clientId(): any {
    return `${AppConfig.settings.client_id}`;
  }
  public static get redirectUri(): any {
    return `${AppConfig.settings.redirect_uri}`;
  }
  public static get audience(): any {
    return `${AppConfig.settings.audience}`;
  }
  public static get logoutUri(): any {
    return `${AppConfig.settings.logout_uri}`;
  }

  public static get editProfileUrl(): any {
    return `${AppConfig.settings.editProfileUrl}`;
  }
  public static get accountServiceUrl(): any {
    return `${AppConfig.settings.accountServiceUrl}`;
  }
}
