export const locale = {
  lang: "en",
  localeData: {
    NAV: {
      APPLICATIONS: "Applications",
      CALENDAR: "Calendar",
      CHAT: "Chat",
      CHANNEL: "Channel",
      CONTACTS: "Contacts",
      TAG: "Tag",
      CUSTOMIZE: "Customize",
    },
    theme: {
      themeColor: "Theme Color",
      selectYourTheme: "Select your theme",
      light: "Light",
      dark: "Dark",
    },
    settings: "Settings",
    customize: "Customize",
    customFunction: "Settings",
    TITLE: "My i18n Application (en)",
    errorMessage: "Some error occured",
    selectYourLanguage: "Select your language",
    language: "Language",
    noSpaceAssigned: "No Space Assigned",
    login: {
      capital: "LOGIN",
      lower: "login",
      pascal: "Login",
      spaceErrorMessage:
        "You are not associated with any space, Please contact your system administrator",
      signInHeaderMessage: "Sign in failed!",
      spaceNotFound:
        "Failed to get space information, Please contact your system administrator",
      loginError: "Invalid Email or Password.",
      error: {
        dismiss: "Dismiss",
        rateLimit: {
          informationMessage: "Too many users log in at the same time.",
          instructionMessage: "Please try again in a few minutes."
        },
        unregistered: {
          informationMessage: "The e-mail address is not yet registered.",
          instructionMessage: "Please ask your space owner to invite you to Space."
        },
        initialization: {
          informationMessage: "Failed to link your account to DIVA Account.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        internal: {
          informationMessage: "Failed to log in due to an error.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        invalidDomain: {
          informationMessage: "The e-mail address does not use external authentication.",
          instructionMessage: "Please try again with an e-mail that can use external authentication."
        },
        sessionExpired: {
          informationMessage: "Your session has expired.",
          instructionMessage: "Please log in again."
        },
      }
    },
    group: "Group",
    overview: "Overview",
    notification: "Notifications",
    name: {
      first: "First Name",
      last: "Last Name",
      nick: "Nick Name",
    },
    loginId: "Login ID (Email)",
    password: {
      password: "Password",
      forgot: "Forgot Password?",
      confirm: "Password (Confirm)",
      confirmIsRequired: "Password confirmation",
      match: "Password must match",
      forgetPasswordEmailMessage:
        "An mail has been sent to your id, please click on the link in the mail to reset your password!",
      forgetPasswordDialogHeading: "Password reset mail sent",
      forgetPasswordErrorMessage: "Password reset failed!",
    },
    rememberMe: "Remember Me",
    enterEmail: "Please enter a valid email address",
    logout: "Logout",
    profile: {
      my: "My Profile",
      profile: "Profile",
      edit: "Edit Profile",
    },
    editProfileEnterName: "Enter the name you would like to use in Conflow",
    editProfileError: "Failed to upload profile info",
    save: "Save",
    saving: "Saving..",
    EDIT: "Edit",
    REMOVE: "Remove",
    DOWNLOAD: "Download",
    Actions: "Actions",
    Delete: "Delete",
    cancel: "Cancel",
    create: "Create",
    confirm: "Confirm",
    status: "Status",
    activity: "Activity",
    directory: "Directory",
    yourFile: "Your Files",
    ok: "OK",
    register: "Register",
    nickname: "Nick Name",
    uploadPicture: "Upload picture",
    removePicture: "Remove picture",
    menu: "menu",
    "translate-en": "Translate to English",
    "translate-ja": "Translate to Japanese",
    account: {
      have: "Already have an account",
      dont: "Do not have an account",
      create: {
        capital: "CREATE AN ACCOUNT",
        lower: "create an account",
        pascal: "Create an account",
      },
    },
    resetPassword: {
      capital: "RECOVER YOUR PASSWORD",
      lower: "recover your password",
      pascal: "Recover your password",
    },
    resetLink: {
      capital: "SEND RESET LINK",
      lower: "send reset link",
      pascal: "Send reset link",
    },
    backToLogin: "Go back to login",
    acceptTerms: "I read and accept",
    termsCondition: "terms and conditions",
    select: "Select",
    send: "Send",
    email: "Email",
    searchField: "Search Anything Here",
    validation: {
      isRequired: "{{field}} is required",
    },
    conversation: {
      inputMessage: "Type your message here. Type @ to mention someone.",
      uploadFile: "Upload file",
      includeFile: "Include File",
      edited: "edited",
      messageDeleteConfirmationDialogHeader: "Delete Message",
      messageDeleteConfirmationMessage:
        "Are you sure you want to delete this message? This cannot be undone.",
      messageDelete: "(This message was deleted)",
      fileDelete: "(The file was deleted)",
      messageAndFileDelete: "(This message and file was deleted)",
      removedUserName: "(removed user)",
    },
    channel: {
      tabs: {
        conversation: "Conversation",
        files: "Files",
        status: "Status",
        members: "Members",
      },
      status: {
        select: "Select status",
        statusConfirmMessage:
          "Status keys are changed. All statuses are reset. OK?",
      },
      list: {
        create: {
          tooltip: "Create new channel",
          title: "Create New Channel",
          placeholder: "Enter channel name.",
          failedMessage: "The channel could not be created."
        },
        edit: {
          menu: "Edit Channel",
          title: "Edit Channel",
          failedMessage: "The channel could not be edited."
        },
        clone: {
          menu: "Clone Channel",
          title: "Clone Channel",
          nameprefix: "Copy of ",
          progress: "Cloning channel ...",
          placeholder: "Enter new channel name.",
          successMessage: "The new channel was cloned.",
          failedMessage: "The channel could not be cloned."
        },
        delete: {
          menu: "Delete Channel",
          title: "Delete Channel?",
          confirmMessage: "Are you sure you want to delete this channel? This cannot be undone.",
          confirmSubMessage: "Please type the channel name to confirm. (case-sensitive)",
          mismatchAlertMessage: "You entered wrong channel name.",
          failedMessage: "The channel could not be deleted.",
          progress: "Deleting channel ...",
        },
        filter: {
          channel: "Channel filter",
          name: "Filter by name"
        },
        bulkExport: {
          title: "Export Channel",
          failedMessage: "The channels could not be exported",
        }
      },
      welcome: {
        welcomeMessage: "Welcome to Channel!",
        noChannelMessage: "It seems you don't have any channels yet.",
        memberAction: "Please contact your space owner.",
        ownerAction1: "Click the plus ",
        ownerAction2: " icon to create your first channel."
      },
      showChannelTooltip: "Show Channel",
      hideChannelTooltip: "Hide Channel"
    },
    files: {
      fromDate: "From date",
      toDate: "To date",
      fileName: "Name",
      fileType: "Type",
      fileSize: "Size",
      uploadedOn: "Uploaded On",
      uploadedBy: "Uploaded By",
      uploadUserName: "User Name",
      tags: "Tags",
      fileDeletionConfirmationMessage:
        "Are you sure you want to delete this file?",
      fileDeleteConfirmationDialogHeader: "Delete File",
      file: "File",
      fileSelectionMessage: "Please choose a file",
      fileUploadErrorMessage: "Failed to upload file to server",
      filePreviewErrorMessage: "Failed to get file preview",
      ScrollLoadingText: "Fetching files...",
      NoData: "No Data Found",
      RowFilterText: "Search",
      AttachToNewMessage: "Attach file to new message",
      filters: {
        simpleTagFilter: "Tag Value Simple Filter",
        simpleTagCategoryFilter: "Tag Category Simple Filter",
        TagcategryHierarchyFilter: "Tag Category Hierarchy Filter",
      },
      editTags: "Edit Tags",
      renameFile: "Rename File",
      createFile: "Creating file..."
    },
    fileUpload: {
      title: "Upload file",
      chooseFile: "Choose a file",
      addMessageToFile: "Add message to file",
      addTagsToFile: "Add tags to file",
      upload: "Upload",
      fileUploadMaxSizeErrorMessage: "This file is too large to upload",
      storageLimitExceededMessage:
        "File storage limit exceeded. Please contact administrator.",
      invalidFileNameMessage:
        "Invalid file name. Supported file names of 255 characters or less.\"/\" or \"\\\", trailing spaces, and the special names \".\" and \"..\" are not allowed.",
      fileNameTooLongMessage:
        "File name is too long. Supported file names of 255 characters or less.",
      fileAlreadyExistModelTitle: "File Already Exist",
      fileAlreadyExistConfirmMessage:
        "Files {files} already exist. Do you want to replace?",
      sameFileNameNotAllowed: "Please select files with different name",
    },
    filesTagEdit: {
      title: "Edit File Tags",
    },
    fileRename: {
      title: "Rename File",
      fileName: "File Name",
    },
    members: {
      userName: "User name",
      email: "E-Mail Address",
      filterUsers: "Filter Users",
      successMessage: "Successfully updated the channel users",
      errorMessage: "Failed to update users in channel",
      fullName: "Full Name",
      displayName: "Display Name",
    },
    tagsSelection: {
      typeTagHere: "Type tag name here...",
      typeTagCategoryHere: "Type tag category name here...",
    },
    tagGrid: {
      confirmDeleteMsg: "Are you sure you want to delete this tag category?",
      confirmDeleteTitle: "Delete Tag Category",
      categoryName: "Category Name",
      description: "Description",
      uploadedBy: "Updated By",
      uploadedOn: "Updated On",
      search: "Search..",
      tag: {
        addTag: "Add a new tag",
        name: "Tag Name",
        description: "Description",
        confirmDeleteTitle: "Delete Tag",
        confirmDeleteMsg: "Are you sure you want to delete this tag?",
        isEnable: "Enabled",
        isVisible: "Visible",
        uploadedBy: "Updated By",
        uploadedOn: "Updated On",
        notAuthorized: "You are not authorized to create Tag",
      },
    },
    addTagCategory: {
      addNewTagCategory: "Add new tag category",
      writeTagCategoryName: "Write a tagcategory Name",
      requiredTagCategory: "Tag Category Name is required.",
      notAuthorized: "You are not authorized to create Tag-Category",
    },
    tags: {
      categoryDeleteMessage:
        "Tag Category is currenlty being used. Do you still want to delete it?",
      categoryNameConfirmMessage:
        "Please type the category name to confirm. (case-sensitive)",
      categoryNameMismatchAlertMessage: "You entered wrong category name",
      tagInUseMessage:
        "Tag is currenlty being used by others. Do you still want to delete it?",
      addNewTagCategory: "Add new tag category",
    },
    space: {
      spaceSwitchConfirmMessage: "Are you sure you want to change the space?",
      spaceDialogHeader: "Space Switch?",
    },
    newMessage: "New Messages",
    yesterday: "Yesterday",
    today: "Today",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    th: "th",
    st: "st",
    nd: "nd",
    rd: "rd",
    "no-permission": {
      accessMessage:
        "You don't have permission to access <b>[spaceName]</b> because you are not the Space Owner of this space.",
      spaceSelectMessage: "Please select other space from space selector.",
      noPermissionMessage: "You don't have permission to access this space.",
      noSpaceAssignedMessage: "You do not have any Spaces assigned.",
      noSubscriptionMessage: "You don't have subscription to this product."
    },
    "notificationhub": {
      "reconnectbanner": {
        "information": "Live updates are not available due to connection issues.",
        "instruction": "Reconnect to re-establish live updates connection.",
        "action": "Reconnect"
      }
    }
  }
};
