export const locale = {
  lang: "ja",
  localeData: {
    NAV: {
      APPLICATIONS: "アプリケーション",
      CALENDAR: "カレンダー",
      CHAT: "チャット",
      CHANNEL: "チャンネル",
      CONTACTS: "連絡先",
      TAG: "タグ",
      CUSTOMIZE: "カスタマイズ",
    },
    theme: {
      themeColor: "テーマカラー",
      selectYourTheme: "テーマを選択する",
      light: "明るい",
      dark: "暗い",
    },
    settings: "設定",
    customize: "カスタマイズ",
    customFunction: "設定",
    TITLE: "",
    selectYourLanguage: "言語を選択する",
    language: "言語",
    noSpaceAssigned: "スペースが割り当てられていません",
    errorMessage: "",
    login: {
      capital: "ログイン",
      lower: "ログイン",
      pascal: "ログイン",
      spaceErrorMessage:
        "利用できるスペースがありません。管理者にお問い合わせください。",
      signInHeaderMessage: "ログインに失敗しました。",
      spaceNotFound:
        "スペース情報が取得できませんでした。管理者にお問い合わせください。",
      loginError: "ユーザー名またはパスワードが間違っています。",
      error: {
        dismiss: "閉じる",
        rateLimit: {
          informationMessage: "多くのユーザーが同時にログインしました。",
          instructionMessage: "数分後にもう一度お試しください。",
        },
        unregistered: {
          informationMessage: "メールアドレスが未登録です。",
          instructionMessage: "スペースオーナーにスペースへの招待を依頼してください。",
        },
        initialization: {
          informationMessage: "お客様のアカウントでDIVAアカウントへのリンクを失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。",
        },
        internal: {
          informationMessage: "エラーによりログインに失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。",
        },
        invalidDomain: {
          informationMessage: "このメールアドレスは外部認証を利用できません。",
          instructionMessage: "外部認証を利用できるメールアドレスで再度お試しください。"
        },
        sessionExpired: {
          informationMessage: "セッションの有効期限が切れました。",
          instructionMessage: "再度ログインしてください。"
        },
      },
    },
    group: "グループ",
    overview: "概要",
    notification: "通知",
    name: {
      first: "名",
      last: "姓",
      nick: "ニックネーム",
    },
    loginId: "ログインID（電子メール）",
    password: {
      password: "パスワード",
      forgot: "パスワードをお忘れですか？",
      confirm: "パスワード（確認）",
      confirmIsRequired: "パスワードの確認",
      match: "パスワードが一致している必要があります",
      forgetPasswordEmailMessage:
        "メールを送信しました。メール内のリンクをクリックしてパスワードを再設定してください。",
      forgetPasswordDialogHeading: "パスワードリセットメールを送信しました",
      forgetPasswordErrorMessage: "パスワードリセットに失敗しました",
    },
    rememberMe: "ログイン情報を保存する",
    enterEmail: "有効なメールアドレスを入力してください",
    logout: "ログアウト",
    profile: {
      my: "私のプロフィール",
      profile: "プロフィール",
      edit: "プロフィール編集",
    },
    editProfileEnterName: "表示したい名前を入力してください",
    editProfileError: "プロフィールの更新に失敗しました",
    save: "保存する",
    saving: "保存中..",
    EDIT: "編集",
    REMOVE: "削除",
    Actions: "アクション",
    Delete: "削除",
    cancel: "キャンセル",
    create: "作成",
    confirm: "確認",
    status: "状態",
    activity: "アクティビティ",
    directory: "ディレクトリ",
    yourFile: "あなたのファイル",
    ok: "OK",
    register: "登録",
    nickname: "ニックネーム",
    uploadPicture: "写真を更新する",
    removePicture: "写真を削除する",
    menu: "メニュー",
    "translate-en": "Translate to English",
    "translate-ja": "Translate to Japanese",
    account: {
      have: "すでにアカウントをお持ちですか",
      dont: "アカウントを持っていない",
      create: {
        capital: "アカウントを作成する",
        lower: "アカウントを作成する",
        pascal: "アカウントを作成する",
      },
    },
    resetPassword: {
      capital: "パスワードリセット",
      lower: "パスワードリセット",
      pascal: "パスワードリセット",
    },
    resetLink: {
      capital: "メールを送信する",
      lower: "メールを送信する",
      pascal: "メールを送信する",
    },
    backToLogin: "ログイン画面に戻る",
    acceptTerms: "規約に同意します",
    termsCondition: "規約と条件",
    select: "選択",
    send: "投稿",
    tenant: "テナント",
    email: "電子メール",
    searchField: "何でもここで検索",
    validation: {
      isRequired: "{{field}} は必須です",
    },
    conversation: {
      inputMessage: "チャンネルへのメッセージ",
      uploadFile: "ファイルアップロード",
      includeFile: "ファイルも削除する",
      edited: "編集済",
      messageDeleteConfirmationDialogHeader: "メッセージを削除",
      messageDeleteConfirmationMessage:
        "このメッセージを本当に削除しますか？ この作業は元に戻せません。",
      messageDelete: "(このメッセージは削除されました)",
      fileDelete: "(ファイルが削除されました)",
      messageAndFileDelete: "(このメッセージとファイルは削除されました)",
      removedUserName: "(removed user)",
    },
    channel: {
      tabs: {
        conversation: "会話",
        files: "ファイル",
        status: "ステータス",
        members: "メンバー",
      },
      status: {
        select: "ステータスを選んでください",
        statusConfirmMessage:
          "キーが変更されました。設定したステータスは初期化されます。よろしいですか？",
      },
      list: {
        create: {
          tooltip: "チャンネル作成",
          title: "チャンネル作成",
          placeholder: "チャンネル名を入力してください。",
          failedMessage: "チャンネルを作成できませんでした。"
        },
        edit: {
          menu: "チャンネルを編集",
          title: "チャンネルを編集",
          failedMessage: "チャンネルを編集できませんでした。"
        },
        clone: {
          menu: "チャンネルを複製",
          title: "チャンネルを複製",
          placeholder: "新しいチャンネル名を入力してください。",
          nameprefix: "コピー - ",
          progress: "チャンネルを複製しています...",
          successMessage: "チャンネルの複製が完了しました。",
          failedMessage: "チャンネルの複製に失敗しました。",
        },
        delete: {
          menu: "チャンネルを削除",
          title: "チャンネルを削除しますか？",
          confirmMessage: "このチャンネルを本当に削除しますか？ この作業は元に戻せません。",
          confirmSubMessage: "確認のためチャンネル名を入力してください。",
          mismatchAlertMessage: "チャンネル名が誤っています。",
          failedMessage: "チャンネルを削除できませんでした。",
          progress: "チャンネルを削除しています..."
        },
        filter: {
          channel: "チャンネルフィルター",
          name: "チャンネル名でフィルター"
        },
        bulkExport: {
          title: "チャンネルをエクスポート",
          failedMessage: "チャンネルのエクスポートに失敗しました。"
        }
      },
      welcome: {
        welcomeMessage: "ようこそ！",
        noChannelMessage: "まだチャンネルが作成されていません。",
        memberAction: "管理者にお問い合わせください。",
        ownerAction1: "",
        ownerAction2: " アイコンをクリックしてチャンネルを作成しましょう。"
      },
      showChannelTooltip: "チャンネルを表示",
      hideChannelTooltip: "チャンネルを隠す"
    },
    files: {
      fromDate: "開始日",
      toDate: "終了日",
      fileName: "ファイル名",
      fileType: "ファイルタイプ",
      fileSize: "ファイルサイズ",
      uploadedOn: "更新日時",
      uploadedBy: "更新者",
      uploadUserName: "更新者名",
      tags: "タグ",
      fileDeletionConfirmationMessage: "このファイルを本当に削除しますか？",
      fileDeleteConfirmationDialogHeader: "ファイルを削除する",
      file: "ファイル",
      fileSelectionMessage: "ファイルを選んでください",
      fileUploadErrorMessage: "ファイルのアップロードに失敗しました",
      filePreviewErrorMessage: "ファイルのプレビュー表示に失敗しました",
      ScrollLoadingText: "ファイルを取得しています...",
      NoData: "何もデータが見つかりませんでした",
      RowFilterText: "サーチ",
      AttachToNewMessage: "新しいメッセージに添付",
      filters: {
        simpleTagFilter: "タグフィルター",
        simpleTagCategoryFilter: "タグカテゴリーフィルター",
        TagcategryHierarchyFilter: "多軸フィルター",
      },
      editTags: "タグを編集",
      renameFile: "ファイル名を変更する",
      createFile: "ファイルを作成しています..."
    },
    fileUpload: {
      title: "ファイルアップロード",
      chooseFile: "ファイルを選択",
      addMessageToFile: "メッセージ",
      addTagsToFile: "ファイルにタグを付ける",
      upload: "アップロード",
      fileUploadMaxSizeErrorMessage:
        "ファイルサイズが大きすぎてアップロードできません",
      storageLimitExceededMessage:
        "ファイルストレージの制限を超えました。 管理者に連絡してください。",
      invalidFileNameMessage:
        "ファイル名が無効です。ファイル名は255文字以下で「/」「\\」は利用できません。また特殊なファイル名「.」「..」は利用できません。",
      fileNameTooLongMessage:
        "ファイル名が長すぎます。255文字以下にしてください。",
      fileAlreadyExistModelTitle: "ファイルは既に存在します",
      fileAlreadyExistConfirmMessage:
        "ファイル{files}は既に存在します。上書きしますか？",
      sameFileNameNotAllowed: "別の名前のファイルを選択してください",
    },
    filesTagEdit: {
      title: "ファイルタグの編集",
    },
    fileRename: {
      title: "ファイル名を変更する",
      fileName: "ファイル名",
    },
    members: {
      userName: "ユーザー名",
      email: "メールアドレス",
      filterUsers: "ユーザーを検索",
      successMessage: "チャンネルメンバーを更新しました",
      errorMessage: "チャンネルメンバーの更新に失敗しました",
      firstName: "名",
      lastName: "姓",
    },
    tagsSelection: {
      typeTagHere: "タグを検索",
      typeTagCategoryHere: "ここにタグのカテゴリ名を入力してください...",
    },
    tagGrid: {
      confirmDeleteMsg: "このタグカテゴリーを本当に削除しますか？",
      confirmDeleteTitle: "タグカテゴリーを削除する",
      categoryName: "カテゴリー名",
      description: "説明",
      uploadedBy: "更新者",
      uploadedOn: "更新日",
      search: "Search..",
      tag: {
        addTag: "タグを追加する",
        name: "タグ名",
        description: "説明",
        confirmDeleteTitle: "タグを削除する",
        confirmDeleteMsg: "このタグを本当に削除しますか？",
        isEnable: "有効",
        isVisible: "表示",
        uploadedBy: "更新者",
        uploadedOn: "更新日",
        notAuthorized: "タグを作る権限がありません",
      },
    },
    addTagCategory: {
      addNewTagCategory: "タグカテゴリーを追加する",
      writeTagCategoryName: "タグカテゴリー名をここに記入",
      requiredTagCategory: "タグカテゴリー名を入力してください",
      notAuthorized: "タグカテゴリーを作る権限がありません",
    },
    tags: {
      categoryDeleteMessage:
        "このタグカテゴリーは使用されています。削除しますか？",
      categoryNameConfirmMessage:
        "確認のためタグカテゴリー名を入力してください。",
      categoryNameMismatchAlertMessage: "カテゴリー名が誤っています。",
      tagInUseMessage: "このタグは使用されています。削除しますか？",
      addNewTagCategory: "タグカテゴリーを追加する",
    },
    space: {
      spaceSwitchConfirmMessage: "スペースを切り替えます。よろしいですか？",
      spaceDialogHeader: "スペースを切り替える",
    },
    newMessage: "新しいメッセージ",
    yesterday: "昨日",
    today: "今日",
    sunday: "日曜日",
    monday: "月曜日",
    tuesday: "火曜日",
    wednesday: "水曜日",
    thursday: "木曜日",
    friday: "金曜日",
    saturday: "土曜日",
    january: "1月",
    february: "2月",
    march: "3月",
    april: "4月",
    may: "5月",
    june: "6月",
    july: "7月",
    august: "8月",
    september: "9月",
    october: "10月",
    november: "11月",
    december: "12月",
    th: "日",
    st: "日",
    nd: "日",
    rd: "日",
    "no-permission": {
      accessMessage:
        "このスペースのスペース所有者ではないため、<b>[spaceName]</b> にアクセスする権限がありません。",
      spaceSelectMessage: "スペースセレクターから他のスペースを選択してください。",
      noPermissionMessage: "このスペースにアクセスする権限がありません。",
      noSpaceAssignedMessage: "スペースが割り当てられていません。",
      noSubscriptionMessage: "このプロダクトにアクセスする権限がありません。"
    },
    "notificationhub": {
      "reconnectbanner": {
        "information": "接続の問題により、ライブアップデートは利用できません。",
        "instruction": "再接続して、ライブアップデート接続を再確立します。",
        "action": "再接続"
      }
    }
  }
};
