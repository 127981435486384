import { ErrorHandler, Injectable } from "@angular/core";
import { AlertService } from "diva-lib";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private alertService: AlertService
  ) { }
  handleError(error): void {
    if (error.error !== undefined && error.error !== null ) {
      this.alertService.showWarningSnakBar("Error Occurred!");
      return;
    }
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }
}
