export const locale = {
  lang: "ja",
  localeData: {
    "actionflow": {
      "startactionflow": "タスクを開始する",
      "editactionflow": "タスクを編集する",
      "createactionflow": "タスクを作成する",
      "report": "タスク状況",
      "information": "情報入力",
      "comment": "コメント",
      "logs": "履歴",
      "loading": "タスクリストをロードしています..",
      "required": "入力必須",
      "actions": {
        "doaction": "申請する",
        "rerun": "やり直す",
        "cancel": "対象外",
        "approval": "承認",
      },
      "commands": {
        "create": "タスクを作成..",
        "export": "一覧をエクスポート..",
        "reload": "リロード",
        "update": "編集..",
        "clone": "コピーしてタスクを追加..",
        "delete": "削除..",
        "deletemessage": "このタスクを削除します。よろしいですか？",
        "deletetitle": "タスクを削除する",
        "save": "保存",
        "loadmore": "もっと見る",
        "back": "戻る",
        "loaduser": "ユーザ名を表示.."
      },
      "properties": {
        "id": "ID",
        "name": "名前",
        "nameexample": "Ex. 固定資産（国内）",
        "status": "ステータス",
        "action": "アクション",
        "actorexample": "担当ユーザ",
        "actorlabel": "表示ラベル",
        "actorlabelexample": "Ex. 山田",
        "step": "ステップ",
        "completed": "完了",
        "lastacteduser": "最終実行ユーザ",
        "lastacteddate": "最終実行日時",
        "customfield": "追加項目",
        "basicinfo": "基本設定",
        "stepinfo": "ステップ設定",
      },
      "steps": {
        "cancel": "対象外",
        "charger": "担当",
        "cross": "クロス",
        "cross1": "クロス1",
        "cross2": "クロス2",
        "chief": "主任",
        "manager": "課長",
      },
      "messages": {
        "hasunknownuser": "チャンネルに登録されていないユーザが設定されています。設定を見直してください。",
        "nodiststeponrerun": "やり直す場合は戻し先のステップを選択してください。",
        "nocomment": "コメントを入力してください。",
        "hasempty": "すべての項目を入力してください。",
      },
      "templates": {
        "label": "本文テンプレート",
        "1": {
          "name": "承認依頼用項目",
          "text": "〇案件内容\n\n\n〇保存フォルダ\n\n\n〇押印フォルダ\n\n\n〇コメント\n\n\n",
        },
        "2": {
          "name": "差し戻し用項目",
          "text": "〇差し戻しコメント\n\n\n〇資料フォルダ\n\n\n",
        }
      },
      "errors": {
        "nomessage": "メッセージを入力してください。",
        "notselectbackto": "やり直す先のステップを選択してください。",
        "taskdeleted": "このTaskは削除されました。",
        "removeassociation": "このTaskはチャンネルから削除されました。"
      },
      "notfound": {
        "contenttitle": "タスクが見つかりません",
        "message": "タスクが既に削除されています。",
        "action": "チャンネルへ戻る"
      },
      "filter": {
        "clear": "フィルタ解除",
        "all": "すべてを選択・解除"
      },
      "detail": {
        "banner": {
          "informationmessage": "このタスクには新しい更新があります。",
          "instructionmessage": "ページを更新してください。",
          "actionbuttontext": "更新"
        }
      }
    }
  }
};
