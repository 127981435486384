import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { basicLogger, initialize, LDClient, LDFlagSet } from "launchdarkly-js-client-sdk";
import { ConflowSettings } from "../settings/conflow-settings";
import { GlobalService } from "../services/global.service";

@Injectable({
  providedIn: "root"
})
export class FeatureFlagService {
  public ldClient: LDClient;
  public flags: LDFlagSet;
  public flagChange: Subject<Object> = new Subject<Object>();
  public key: string;
  private userIdAndTenant: string;

  constructor(private globalService: GlobalService) {
    this.userIdAndTenant = `${this.globalService.currentUserId } | ${this.globalService.currentSpaceId}`;
    this.key = this.userIdAndTenant ? this.userIdAndTenant : ConflowSettings.LaunchDarklyKey;
    this.initialize();
  }

  initialize() {
    this.flags = ConflowSettings.FeatureFlags;

    this.ldClient = initialize(ConflowSettings.LaunchDarklyClientID,
      { key: this.key },
      { logger: basicLogger({ level: "warn" }) }
    );

    this.ldClient.on("change", (flags) => {
      this.renderFlags(flags, ConflowSettings.FeatureFlags);
    });

    this.ldClient.on("ready", () => {
      this.renderFlags(this.flags, ConflowSettings.FeatureFlags);
    });
  }

  public renderFlags(flags, names) {
    for (const name in names) {
      if (names.hasOwnProperty(name)) {
        this.flags[name] = flags[name];
      }
    }

    this.flags = this.ldClient.allFlags();
    this.flagChange.next(this.flags);
  }
}