import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { LoginComponent } from "./core/auth/login/login.component";
import { AuthGuard } from "@auth0/auth0-angular";
import { LoginCallbackComponent } from "./core/auth/logincallback/login.callback/login.callback.component";

const routes: Routes = [
  { path: "", redirectTo: "index", pathMatch: "full" },
  {
    path: "index",
    component: LoginComponent,
  },
  {
    path: "logincallback",
    component: LoginCallbackComponent,
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./features/features.module").then((module) => module.FeaturesModule),
    runGuardsAndResolvers: "always",
  },
  {
    path: "permission",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./features/permission/permission.module").then(
        (module) => module.PermissionModule
      ),
    runGuardsAndResolvers: "always",
  },
];

const config: ExtraOptions = {
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
