import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActionFlowLoaderService } from "src/plugins/actionflow/actionflow-loader.service";

@Injectable({
  providedIn: "root"
})

export class PluginLoaderService {
  /**
   * Constructor
   *
   * @param {TranslateService} translateService
   */
  constructor(
        private translateService: TranslateService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Load
   */
  load(): void {
    new ActionFlowLoaderService(this.translateService).load();
  }
}
