import { Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./auth/login/login.component";
import { SharedModule } from "../shared/shared.module";
import { GlobalErrorHandler } from "../shared/utils/global-error-handler";
import { ThemeModule } from "./theme.module";
import { LoginCallbackComponent } from "./auth/logincallback/login.callback/login.callback.component";
import { AppConfig } from "../app.config";
@NgModule({
  declarations: [
    LoginComponent, LoginCallbackComponent],
  imports: [
    CommonModule,
    SharedModule,
    ThemeModule.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler, deps: [AppConfig] },
  ],
  exports: [
    SharedModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot() {
    return {
      ngModule: CoreModule,
    };
  }
}

