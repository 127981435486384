import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { ConflowSettings } from "./shared/settings/conflow-settings";
import { TranslationLoaderService } from "./shared/services/translation-loader.service";
import { locale as translateEnglish } from "../assets/i18n/en";
import { locale as translateJapanese} from "../assets/i18n/ja";
import { PluginLoaderService } from "src/plugins/plugin-loader.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "TaskFront";

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {TranslateService} translate
   * @param {TranslationLoaderService} translationLoaderService
   */

  constructor(
            @Inject(DOCUMENT) private document: any,
            private translate: TranslateService,
            private translationLoaderService: TranslationLoaderService,
            private pluginLoader: PluginLoaderService
  ) {
    const localStoreLang: string = localStorage.getItem("localStoreLang");
    const browserLang: string = this.translate.getBrowserLang();
    // add languages

    this.translate.addLangs(ConflowSettings.getLanguagesIdInArray);
    // set default language for app
    this.translate.setDefaultLang(
      localStoreLang ? localStoreLang
        : browserLang.match(ConflowSettings.getLanguagesIdInArray.join("|"))
          ? browserLang : ConflowSettings.defaultLanguage
    );
    // Setting the default language
    localStorage.setItem("localStoreLang", this.translate.getDefaultLang());

    // Set the navigation translations
    this.translationLoaderService.loadTranslations(translateEnglish, translateJapanese);

    // plugin loader
    this.pluginLoader.load();
  }
}

