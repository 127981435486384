<mat-sidenav-container>
    <mat-sidenav #sidenav *ngIf="!hideSideNav" [@openClose]="isExpanded ? 'open' : 'closed'" mode="side" opened
      fullscreen>
      <div class="logo-container">
        <mat-icon class="menu-icon" (click)="toggleSidebar()">menu</mat-icon>
        <img class="logo" *ngIf="isExpanded" src="assets/images/Diva-Cloud-Logo-2.svg" />        
      </div>
      <mat-list>
        <a mat-list-item  class="active" [routerLink]="['/dashboard/channel']">
          <mat-icon matListItemIcon class="nav-icon-image">people</mat-icon>
          <span matListItemTitle class="menu-item-label">{{'NAV.CHANNEL'| translate}}</span>
        </a>
      </mat-list>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="hideSideNav?'no-side-nav':''" [@openClose]="isExpanded ? 'shiftRight' : 'shiftLeft'"
      mode="side">
      <div class="content-container">
        <div class="header-area">
            <diva-header #divaHeader *ngIf="!hideHeader" 
            [showHeaderLogo]="hideSideNav?true:false"
                [EditProfileString]="'profile.edit' | translate" 
                [LanguageSettingString]="'language' | translate" 
                headerLogo="assets/images/Diva-Cloud-Logo-2.svg"
                [displayTheme]="hideSideNav?'dark':''"
                [NoSpaceAssignedString]="'noSpaceAssigned' | translate" 
                [LogoutString]="'logout'| translate" 
                (LanguageId)="changeLang($event)" 
                [ServerUrl]="accountServiceUrl" 
                [Languages]="languages" 
                [EditProfileUrl]="editProfileUrl"                
                [ShowEditProfileLink]="true" 
                [ShowSpaceList]="true" 
                (SpaceEmitter)="changeSpace($event)" 
                [SelectedSpace]="currentSpaceId" 
                (LogoutEmitter)="authService.logout({client_id: clientId, logoutParams: { returnTo: document.location.origin + '/index' }})" 
                (UserEmitter)="globalService.setUser($event)">
            </diva-header>
        </div>
        <div class="content-area">
          <div id="diva-banner-container">
            <!-- Do not remove this div. It is a container where banner show. -->
          </div>
          <ng-content select="router-outlet"> </ng-content>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>