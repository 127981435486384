import { NgModule } from "@angular/core";
import { DashBoardComponent } from "./dashboards/dashboard.component";
import { SharedModule } from "../shared/shared.module";

const COMPONENTS = [
  DashBoardComponent
];

@NgModule({
  imports: [SharedModule],
  exports: [ ...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ThemeModule {
  static forRoot() {
    return {
      ngModule: ThemeModule,
      providers: [],
    };
  }
}
