import { Component, OnDestroy, Inject, OnInit, ViewChild, Input } from "@angular/core";
import { GlobalService } from "src/app/shared/services/global.service";
import { DOCUMENT } from "@angular/common";
import { AuthService } from "@auth0/auth0-angular";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { languages } from "src/app/shared/const/lang";
import { ConflowSettings } from "src/app/shared/settings/conflow-settings";
import { HeaderComponent, AlertService } from "diva-lib";
import { MatDialogRef } from "@angular/material/dialog";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { ConfirmComponent } from "src/app/shared/components/alert/confirm/confirm.component";

@Component({
  selector: "app-dashboard",
  animations: [
    trigger("openClose", [
      state("open", style({
        width: "240px",
      })),
      state("closed", style({
        width: "64px"
      })),
      state("shiftLeft", style({
        marginLeft: "64px"
      })),
      state("shiftRight", style({
        marginLeft: "240px"
      })),
      transition("open => closed", [
        animate("0.3s")
      ]),
      transition("closed => open", [
        animate("0.3s")
      ]),
      transition("shiftLeft => shiftRight", [
        animate("0.3s　ease-in-out")
      ]),
      transition("shiftRight => shiftLeft", [
        animate("0.3s　ease-in-out")
      ]),
    ]),
  ],
  styleUrls: ["./dashboard.component.scss"],
  templateUrl: "./dashboard.component.html",
})
export class DashBoardComponent implements OnInit, OnDestroy {
  private unsubscribeAll = new Subject<any>();
  public accountServiceUrl = ConflowSettings.accountServiceUrl;
  public editProfileUrl = ConflowSettings.editProfileUrl;
  public clientId = ConflowSettings.clientId;

  languages: object[];
  currentSpaceId: string;
  @ViewChild("divaHeader") divaHeader: HeaderComponent;
  @Input() hideSideNav: boolean = false;
  @Input() hideHeader: boolean = false;
  confirmDialogRef: MatDialogRef<ConfirmComponent>;
  public isExpanded: boolean = false;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public globalService: GlobalService,
    private translateService: TranslateService,
    private alertService: AlertService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    document.getElementById("global-spinner").style.display = "none";
    this.languages = languages;
    this.currentSpaceId = this.globalService.currentSpaceId;
    this.globalService.onSpaceChanged
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((spaceId) => {
        this.currentSpaceId = spaceId;
      });
  }

  changeLang(langId: string) {
    this.translateService.use(langId);
    this.translateService.defaultLang = langId;
    localStorage.setItem("localStoreLang", langId);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.unsubscribe();
  }

  changeSpace(spaceId: string) {
    this.currentSpaceId = spaceId;
    forkJoin([
      this.translateService.get("space.spaceSwitchConfirmMessage"),
      this.translateService.get("space.spaceDialogHeader")
    ]).subscribe(([message, title]) => {
      this.alertService
        .confirm(
          message,
          title,
          null,
          null,
          false,
          null,
          true,
          true
        ).subscribe((res: boolean) => {
          if (res) {
            this.globalService.changeSpace(spaceId);
            this.currentSpaceId = spaceId;
          } else {
            this.currentSpaceId = this.globalService.currentSpaceId;
          }
        });
    });
  }

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
  }
}
