<div id="diva-banner-container">
</div>

<div id="login" class="inner-scroll">

    <div id="login-intro">

        <div class="logo">
            <span class="login-logoicon">Image</span>
            <span class="login-logotext">
                DIVA Cloud
            </span>
        </div>

        <div class="description">
            <img id="login-logotext" class="logo-text" src="assets/images/logos/undraw_data_xmfy.svg">
        </div>

    </div>

    <div id="login-form-wrapper">
        <mat-progress-bar color="primary" *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

        <div id="login-form" [@slideInLeft]>

            <div class="logo">
                <div class="login-form-logoicon"></div>
                <div class="login-form-logotext"></div>
            </div>

            <form name="loginForm" novalidate>
                <button mat-flat-button color="primary" class="submit-button" aria-label="LOGIN"
                    (click)="login()">
                    {{ 'login.capital' | translate }}
                </button>
            </form>
        </div>
    </div>
</div>